import React, { Component } from 'react';
import Page from './page';
import clickMenuLateral from '../../redux/actions/actionbar/clickMenuLateral'
import clickMensajes from '../../redux/actions/actionbar/clickMensajes'
import clickNotificaciones from  '../../redux/actions/actionbar/clickNotificaciones'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


class IAppBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };

        this.onClickMenuLateral =  this.onClickMenuLateral.bind(this);
        this.onClickMensajes =  this.onClickMensajes.bind(this);
        this.onClickNotificaciones =  this.onClickNotificaciones.bind(this);
    }

    render() {
        
        return (
            <Page
                tituloAppBar="App ENGIE"
                nombreUsuario={this.props.name_correo}
                urlImagenUsuario={this.props.avatar_url}
                cantidadMensajes={100}
                cantidadNotificaciones={0}
                notificacionesIsVisible="true"
                mensajesIsVisisble="true"
                onClickMenuLateral = {this.onClickMenuLateral}
                onClickMensajes =  {this.onClickMensajes}
                onClickNotificaciones = {this.onClickNotificaciones}
                isAutheticadoUser="true"
            />
        );
    }

    onClickNotificaciones (){
        const {
            clickNotificaciones,
            match,
            history
        }= this.props;

        clickNotificaciones("CLICK_MENU_NOTIFICACIONES")
        if (match.path !== '/results') {
            history.push('/results');
        }
    }
    onClickMensajes() {
        const {
            clickMensajes,
            match,
            history,
        } = this.props;

        clickMensajes("CLICK_MENU_MENSAJES");
        if (match.path !== '/results') {
            history.push('/results');
        }
    }
    onClickMenuLateral(){
        const {
            clickMenuLateral,
            //match,
            //history,
        } = this.props;

        clickMenuLateral("CLICK_MENU_LATERAL");
        //if (match.path !== '/results') {
        //    history.push('/results');
        //}
    }
    
}



function mapStateToProps(state) {
    if (state.appBarAuth.accion === "UPDATE_AUTH_USER_ACTION_BAR") {
        return {
            avatar_url:state.appBarAuth.avatar_url,
            name_correo:state.appBarAuth.user_correo
        }
    }
    return {

    }
}

const mapDispatchToProps = {
    clickMenuLateral,
    clickMensajes,
    clickNotificaciones

};


// conectar un componente con redux 
export default withRouter(
    connect(mapStateToProps,mapDispatchToProps)(IAppBar)
);
